import Vue from 'vue';
import currency from 'currency.js'

Vue.filter('currency', function (value) {
    if (typeof value === 'undefined' || value == null) {
        return '';
    }

    let p = countDecimalDigits(value);
    const FORMAT = value => currency(value, { precision: p });
    return FORMAT(value).format();
});

Vue.filter('number', function (value) {
    if (typeof value === 'undefined' || value == null) {
        return value;
    }

    let p = countDecimalDigits(value);
    const FORMAT = value => currency(value, { precision: p, symbol: '' });
    return FORMAT(value).format();
});

function countDecimalDigits(number) {
    const numberString = number.toString();

    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex !== -1) {
        let decimalDigits = numberString.length - decimalIndex - 1;
        while (decimalDigits > 0 && numberString.endsWith('0')) {
            numberString.slice(0, -1);
            decimalDigits--;
        }

        return decimalDigits ? decimalDigits : 0;
    }

    return 0;
}