export default [
  {
    path: '/sales-credit-memo',
    name: 'list-sales-credit-memo',
    component: () => import('@/views/salesCreditMemo/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.creditNote',
      resource: 'agent-sales-credit-memo',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCreditMemo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-credit-memo/:id',
    name: 'view-sales-credit-memo',
    component: () => import('@/views/salesCreditMemo/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.creditNote',
      resource: 'agent-sales-credit-memo',
      navActiveLink: 'list-sales-credit-memo',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCreditMemo',
          to: { name: 'list-sales-credit-memo' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
