export default [
  {
    path: '/product',
    name: 'list-product',
    component: () => import('@/views/product/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.product',
      resource: 'agent-product',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listProduct',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/product/:id',
  //   name: 'view-product',
  //   component: () => import('@/views/product/View.vue'),
  //   meta: {
  //     pageTitle: 'breadcrumb.productDetail',
  //     navActiveLink: 'list-product',
  //     resource: 'agent-product',
  //     action: 'update',
  //     breadcrumb: [
  //       {
  //         text: 'breadcrumb.listProduct',
  //         to: { name: 'list-product' }
  //       },
  //       {
  //         text: 'breadcrumb.productDetail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
