<template>
  <b-input-group :size="size" class="quantity-input">
    <b-input-group-prepend>
      <n-button-loading
        type="button"
        variant="outline-secondary"
        :loading="loading"
        :disabled="value <= 1 || disabled || loading"
        :size="size"
        @click="minus"
      >
        <feather-icon icon="MinusIcon" v-if="!loading" />
      </n-button-loading>
    </b-input-group-prepend>
    <cleave
      class="form-control"
      :value="value"
      :options="number"
      :raw="true"
      :disabled="disabled || loading"
      :placeholder="placeholder"
      @input="input"
      @blur.native="blur"
    />
    <b-input-group-append>
      <n-button-loading
        type="button"
        variant="outline-secondary"
        :loading="loading"
        :disabled="disabled || loading"
        :size="size"
        @click="plus"
      >
        <feather-icon icon="PlusIcon" v-if="!loading" />
      </n-button-loading>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import Cleave from "vue-cleave-component";
import {
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading.vue";

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    Cleave,
    NButtonLoading,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  watch: {
    value() {
      this.data = this.value;
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      data: null,
    };
  },
  methods: {
    minus() {
      let value = Number(this.value) - 1;
      value > 1 ? value : 1;
      this.$emit("input", value);
      this.$emit("change", value);
    },
    plus() {
      this.$emit("input", Number(this.value) + 1);
      this.$emit("change", Number(this.value) + 1);
    },
    input(value) {
      if (value < 1) {
        value = 1;
      }
      this.data = value;
      this.$emit("input", value);
    },
    blur() {
      this.$emit("blur", this.data);
    },
  },
};
</script>

<style scoped>
</style>
