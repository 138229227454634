export default [
  {
    path: '/sales-invoice',
    name: 'list-sales-invoice',
    component: () => import('@/views/salesInvoice/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesInvoice',
      resource: 'agent-sales-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listInvoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-invoice/:id',
    name: 'view-sales-invoice',
    component: () => import('@/views/salesInvoice/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesInvoice',
      resource: 'agent-sales-invoice',
      navActiveLink: 'list-sales-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listInvoice',
          to: { name: 'list-sales-invoice' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
