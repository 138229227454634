export default [
  {
    path: '/post-purchase-invoice',
    name: 'list-post-purchase-invoice',
    component: () => import('@/views/postPurchaseInvoice/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.postPurchaseInvoice',
      resource: 'agent-post-purchase-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostPurchaseInvoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post-purchase-invoice/:id',
    name: 'view-post-purchase-invoice',
    component: () => import('@/views/postPurchaseInvoice/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.postPurchaseInvoice',
      resource: 'agent-post-purchase-invoice',
      navActiveLink: 'list-post-purchase-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostPurchaseInvoice',
          to: { name: 'list-post-purchase-invoice' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
