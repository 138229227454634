import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/agent/dashboard`;

export default {
  summary() {
    return Client.post(resource, {}, {
      headers: {
        action: "summary",
      },
    });
  },
  chart(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "chart",
      },
    });
  },
};
