import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/agent/order`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  countInProgress() {
    return Client.post(resource, {}, {
      headers: {
        action: "count-in-progress-order",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  log(payload) {
    let params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "log",
      },
    });
  },
  accept(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "create-sales-order",
        // action: "preparing",
      },
    });
  },
  shipping(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "shipping",
      },
    });
  },
  complete(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "complete",
      },
    });
  },
  cancel(id, note) {
    const params = {
      id: id,
      note: note,
    };
    return Client.post(resource, params, {
      headers: {
        action: "reject",
      },
    });
  },
};
