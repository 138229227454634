import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/agent/sales-order/action`;

export default {
  release(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "release",
      },
    });
  },
  reOpen(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "re-open",
      },
    });
  },
  post(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "post",
      },
    });
  },
};
