import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareFilterParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/agent/outlet-shipping-option`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    payload.filter.forEach((element) => {
      params.filters.push(element);
    });
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
