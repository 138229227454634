import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vee-validate";
import "@/libs/acl";
import "@/libs/toastification";
import "@/libs/vue-shortkey";
import { VBTooltipPlugin } from "bootstrap-vue";
import "@/libs/google-map";
import { camelize } from "humps";

import "@/libs/fcm";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

// Filter
import "@/filters/dateFilter";
import "@/filters/currencyFilter";
import "@/filters/phoneFilter";

// Custom
import LoadProfile from "@/components/LoadProfile";
Vue.component("load-profile", LoadProfile);

import imagePreview from "image-preview-vue";
import "image-preview-vue/lib/imagepreviewvue.css";
Vue.use(imagePreview);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VBTooltipPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.prototype.$bus = new Vue();
Vue.mixin({
  methods: {
    displayText(value) {
      if (!value) {
        return "-";
      }

      return value;
    },
    changePage(page = 1) {
      this.query.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.getData();
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    trans(data, label, locale) {
      let tran = data[camelize(`${label}_${locale}`)];
      if (!tran) {
        return data[camelize(`${label}_${this.$i18n.fallbackLocale}`)];
      }
      return tran;
    },
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
  },
});

Vue.prototype.$bus = new Vue();
Vue.prototype.$showLoading = function (
  current = 0,
  total = 0,
  isDownload = false
) {
  this.$bus.$emit("show-loading", {
    total: total,
    current: current,
    isDownload: isDownload,
  });
};
Vue.prototype.$hideLoading = function () {
  this.$bus.$emit("hide-loading");
};
Vue.prototype.$showSyncing = function (module) {
  this.$bus.$emit("show-syncing", {
    module: module,
  });
};
Vue.prototype.$hideSyncing = function () {
  this.$bus.$emit("hide-syncing");
};

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("@/assets/icons/line-awsome-icon/css/line-awesome.min.css");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
