export default {
  namespaced: true,
  state: {
    product: [],
    outlet: [],
    outletModal: [],
    salespersonModal: [],
    user: [],
    role: [],
    order: [],
    salesOrder: [],
    salesInvoice: [],
    salesShipment: [],
    salesReturnOrder: [],
    creditNote: [],
    transferOrder: [],
    transferReceipt: [],
    transferShipment: [],
    purchaseInvoice: [],
    postPurchaseInvoice: [],
    reasonCode: [],
    salesReasonCode: [],
  },
  getters: {},
  mutations: {
    SET_OUTLET_COLUMN(state, val) {
      state.outlet = val
    },
    SET_OUTLET_MODAL_COLUMN(state, val) {
      state.outletModal = val
    },
    SET_SALESPERSON_MODAL_COLUMN(state, val) {
      state.salespersonModal = val
    },
    SET_PRODUCT_COLUMN(state, val) {
      state.product = val
    },
    SET_USER_COLUMN(state, val) {
      state.user = val
    },
    SET_ROLE_COLUMN(state, val) {
      state.role = val
    },
    SET_ORDER_COLUMN(state, val) {
      state.order = val
    },
    SET_SALES_ORDER_COLUMN(state, val) {
      state.salesOrder = val
    },
    SET_SALES_INVOICE_COLUMN(state, val) {
      state.salesInvoice = val
    },
    SET_SALES_SHIPMENT_COLUMN(state, val) {
      state.salesShipment = val
    },
    SET_SALES_RETURN_ORDER_COLUMN(state, val) {
      state.salesReturnOrder = val
    },
    SET_CREDIT_NOTE_COLUMN(state, val) {
      state.creditNote = val
    },
    SET_TRANSFER_ORDER_COLUMN(state, val) {
      state.transferOrder = val
    },
    SET_TRANSFER_RECEIPT_COLUMN(state, val) {
      state.transferReceipt = val
    },
    SET_TRANSFER_SHIPMENT_COLUMN(state, val) {
      state.transferShipment = val
    },
    SET_PURCHASE_INVOICE_COLUMN(state, val) {
      state.purchaseInvoice = val
    },
    SET_POST_PURCHASE_INVOICE_COLUMN(state, val) {
      state.postPurchaseInvoice = val
    },
    SET_REASON_CODE_COLUMN(state, val) {
      state.reasonCode = val
    },
    SET_SALES_REASON_CODE_COLUMN(state, val) {
      state.salesReasonCode = val
    },
  },
  actions: {},
}
