import Vue from 'vue';

Vue.filter('formatPhoneNumber', function (value) {
    if (typeof value === 'undefined' || !value) {
        return '';
    }

    const regex = /(\d{3})(\d{3})(\d{3,4})/;
    const formattedPhoneNumber = value.replace(regex, "$1 $2 $3");
    return formattedPhoneNumber;
});
