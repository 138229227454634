import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/agent/dp/dashboard`;

export default {
  summary(payload = {}) {
    return Client.post(resource, payload, {
      headers: {
        action: "summary",
      },
    });
  },
};
