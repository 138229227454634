import jwtDefaultConfig from './jwtDefaultConfig';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import { encrypt } from '@/libs/crypto-js';
import Swal from 'sweetalert2';
import i18n from "@/libs/i18n";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        // If token is present add it to request's Authorization Header
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = encrypt(this.jwtConfig.authorizationKey);
        config.headers.Locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en";
        config.headers.PLATFORM = "AGENT-WEB";
        config.headers["OS-TYPE"] = "web";
        // If token is present add it to request's Authorization Header
        if (config.params) {
          config.params = decamelizeKeys(config.params);
          if (config.params.order) {
            config.params.order = decamelize(config.params.order);
          }
        }
        if (config.data) {
          config.data = decamelizeKeys(config.data);
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      // response => response,
      response => {
        if (response.data && response.headers["content-type"].startsWith("application/json")) {
          response.data = camelizeKeys(response.data);
        }
        return Promise.resolve(response);
      },
      error => {
        const status = error.response?.status;
        if (!status) {
          Swal.fire({
            imageUrl: require("@/assets/images/icons/undermaintenance.svg"),
            title: i18n.t("error.serverUnderMaintenance"),
            html: `${i18n.t("error.serverUnderMaintenanceMessage")}<br /> <div class="errorcode">${i18n.t("field.code")}: 504</div>`,
            buttonsStyling: false,
            showConfirmButton: false,
            showCloseButton: true,
          });

          return;
        }

        const message = error.response?.data?.errormessage;
        if (message) {
          Swal.fire({
            title: i18n.t("general.attention"),
            icon: "info",
            html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
            buttonsStyling: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.baseUrl + this.jwtConfig.authEndpoint, ...args, {
      headers: {
        action: this.jwtConfig.loginAction,
      }
    });
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.baseUrl + this.jwtConfig.authEndpoint, ...args, {
      headers: {
        action: this.jwtConfig.logoutAction,
      }
    });
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.baseUrl + this.jwtConfig.authEndpoint, {}, {
      headers: {
        action: this.jwtConfig.refreshAction,
        'Re-Authentication': encrypt(this.getRefreshToken()),
      }
    })
  }
}
