export default [
  {
    path: '/post-transfer-shipment',
    name: 'list-post-transfer-shipment',
    component: () => import('@/views/postTransferShipment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.postTransferShipment',
      resource: 'agent-post-transfer-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostTransferShipment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post-transfer-shipment/:id',
    name: 'view-post-transfer-shipment',
    component: () => import('@/views/postTransferShipment/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.postTransferShipment',
      resource: 'agent-post-transfer-shipment',
      navActiveLink: 'list-post-transfer-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostTransferShipment',
          to: { name: 'list-post-transfer-shipment' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
