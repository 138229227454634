import Vue from "vue";
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

let messaging;
firebase.initializeApp(firebaseConfig);

Vue.prototype.$firestore = firebase.firestore();

if (firebase.messaging.isSupported()) {
  // Initialize Firebase

  messaging = firebase.messaging();

  messaging.usePublicVapidKey(process.env.VUE_APP_FCM_PUBLIC_VAPID_KEY);
  Vue.prototype.$messaging = messaging;
}

export default { messaging };