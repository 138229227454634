export default [
  {
    path: '/sales-order',
    name: 'list-sales-order',
    component: () => import('@/views/salesOrder/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesOrder',
      resource: 'agent-sales-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.salesOrderHistory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-order/create',
    name: 'create-sales-order',
    component: () => import('@/views/salesOrder/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesOrder',
      resource: 'agent-sales-order',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.salesOrderHistory',
          to: { name: 'list-sales-order' }
        },
        {
          text: 'breadcrumb.makeOrder',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-order/:id',
    name: 'view-sales-order',
    component: () => import('@/views/salesOrder/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesOrder',
      resource: 'agent-sales-order',
      navActiveLink: 'list-sales-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.salesOrderHistory',
          to: { name: 'list-sales-order' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
