import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/agent/notification`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  countUnread() {
    const params = {};
    return Client.post(resource, params, {
      headers: {
        action: "count-unread-notification",
      },
    });
  },
  read(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "read",
      },
    });
  },
  readAll() {
    const params = {};
    return Client.post(resource, params, {
      headers: {
        action: "read-all",
      },
    });
  },
};
