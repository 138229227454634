export default [
  {
    path: '/sales-shipment',
    name: 'list-sales-shipment',
    component: () => import('@/views/salesShipment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesShipment',
      resource: 'agent-sales-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSalesShipment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-shipment/:id',
    name: 'view-sales-shipment',
    component: () => import('@/views/salesShipment/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesShipment',
      resource: 'agent-sales-shipment',
      navActiveLink: 'list-sales-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSalesShipment',
          to: { name: 'list-sales-shipment' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
