import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/agent/purchase-invoice`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show",
      },
    });
  },
  sync(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "sync",
      },
    });
  },
};
