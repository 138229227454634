export default {
  baseUrl: process.env.VUE_APP_API_URL,
  authEndpoint: '/v1/agent/auth',
  authorizationKey: process.env.VUE_APP_AUTHORIZE_KEY ? process.env.VUE_APP_AUTHORIZE_KEY : 'E2TJKzya8Uz12vi5',

  // Actions
  loginAction: 'login',
  logoutAction: 'logout',
  refreshAction: 'refresh-token',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
