import axios from "axios";
import useJwt from "@/auth/jwt/useJwt"
import { login, logout } from "@/auth/utils";
import router from "@/router";
import Toast from "@/libs/toast";
import { camelizeKeys } from "humps";
import Swal from "sweetalert2";
import { encrypt } from "@/libs/crypto-js";
import i18n from "@/libs/i18n";

const baseDomain = "/api";
const baseURL = `${baseDomain}`;
const auth = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

const request = axios.create({
  baseURL,
  headers: {
    Authentication: auth,
  }
});

request.interceptors.request.use(
  config => {
    config.headers.Locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en";
    config.headers.PLATFORM = 'AGENT-WEB';
    config.headers["OS-TYPE"] = "web";

    // Get token from localStorage
    const accessToken = useJwt.getToken();

    config.headers.Authorization = encrypt(useJwt.jwtConfig.authorizationKey)
    // If token is present add it to request"s Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authentication = `${encrypt(accessToken)}`;
    }
    // if (config.data) {
    //   config.data = decamelizeKeys(config.data);
    // }
    return config
  },
  error => Promise.reject(error),
);


request.interceptors.response.use(function (response) {
  if (response.data?.message) {
    Toast.fire({
      icon: "success",
      title: response.data.message,
    });
  }

  if (response.data && response.headers["content-type"].startsWith("application/json")) {
    response.data = camelizeKeys(response.data);
  }

  return Promise.resolve(response);
}, function (error) {
  const { config, response } = error;
  const status = response?.status;
  if (!status) {
    Swal.fire({
      imageUrl: require("@/assets/images/icons/undermaintenance.svg"),
      title: i18n.t("error.serverUnderMaintenance"),
      html: `${i18n.t("error.serverUnderMaintenanceMessage")}<br /> <div class="errorcode">${i18n.t("field.code")}: 504</div>`,
      buttonsStyling: false,
      showConfirmButton: false,
      showCloseButton: true,
    });

    return;
  }

  const originalRequest = config;
  if (status === 401) {
    const code = response?.data?.code;
    if (code === 102) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        useJwt.refreshToken().then((res) => {
          isAlreadyFetchingAccessToken = false;
          const data = res.data?.data;
          login(data);
          onAccessTokenFetched(data.accessToken);
        }).catch(e => {
          const c = e.response?.data?.code;
          if (c === 104) {
            let message = e.response?.data?.message;
            logout();

            // Redirect to login page
            return router.push({ name: "login" }).then(() => {
              Swal.fire({
                title: i18n.t("general.expired"),
                icon: "info",
                html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
                buttonsStyling: false,
                showConfirmButton: false,
                showCloseButton: true,
              });
            });
          }
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(access_token => {
          originalRequest.headers.Auth = access_token;
          resolve(request(originalRequest))
        })
      });
      return retryOriginalRequest;
    } else if (code === 101 || code === 103 || code === 104) {
      let message = error.response?.data?.message;

      logout();
      // Redirect to login page
      return router.replace({ name: "login" }).then(() => {
        Swal.fire({
          title: i18n.t("general.expired"),
          icon: "info",
          html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
          buttonsStyling: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    } else {
      logout();
      // Redirect to login page
      return router.replace({ name: "login" }).then(() => {
        Swal.fire({
          title: i18n.t("general.expired"),
          icon: "info",
          html: `${i18n.t("error.sessionExpired")}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
          buttonsStyling: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    }
  }

  if (status === 404) {
    return router.push("/error-404");
  }

  if (status === 422) {
    response.data.message = camelizeKeys(response.data?.errors);
  }

  let message = "";
  if (status !== 404 && status !== 401 && status !== 422) {
    message = response?.data?.errormessage;
  }

  if (response.request.responseType === 'blob'
    && response.data.toString() === '[object Blob]' && status <= 500
  ) {
    /** error response */
    response.data.text().then(res => {
      message = JSON.parse(res)?.errormessage;
      Swal.fire({
        title: status < 500 ? i18n.t("general.attention") : i18n.t("general.warning"),
        icon: status < 500 ? "info" : "error",
        html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    })
  } else {
    if (status === 400) {
      Swal.fire({
        title: i18n.t("general.attention"),
        icon: "info",
        html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (status === 504 || status == 502) {
      Swal.fire({
        title: i18n.t("error.timeout"),
        icon: "error",
        html: `${i18n.t("error.timeoutMessage")}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (message) {
      Swal.fire({
        title: i18n.t("general.warning"),
        icon: "error",
        html: `${message}<br /> <div class="errorcode">${i18n.t("field.code")}: ${status}</div>`,
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  }

  // console.clear();
  return Promise.reject(error);
});

export default request;
