export default [
  {
    path: '/transfer-receipt',
    name: 'list-transfer-receipt',
    component: () => import('@/views/transferReceipt/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.transferReceipt',
      resource: 'agent-transfer-receipt',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransferReceipt',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transfer-receipt/:id',
    name: 'view-transfer-receipt',
    component: () => import('@/views/transferReceipt/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.transferReceipt',
      resource: 'agent-transfer-receipt',
      navActiveLink: 'list-transfer-receipt',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransferReceipt',
          to: { name: 'list-transfer-receipt' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
