export default [
  {
    path: '/transfer-shipment',
    name: 'list-transfer-shipment',
    component: () => import('@/views/transferShipment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.transferShipment',
      resource: 'agent-transfer-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransferShipment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transfer-shipment/:id',
    name: 'view-transfer-shipment',
    component: () => import('@/views/transferShipment/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.transferShipment',
      resource: 'agent-transfer-shipment',
      navActiveLink: 'list-transfer-shipment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransferShipment',
          to: { name: 'list-transfer-shipment' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
