import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/libs/i18n";
import { $themeConfig } from "@themeConfig";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import errors from "./routes/errors";
import auth from "./routes/auth";
import profile from "./routes/profile";
import user from "./routes/user";
import role from "./routes/role";
import outlet from "./routes/outlet";
import product from "./routes/product";
import order from "./routes/order";
import salesOrder from "./routes/salesOrder";
import salesInvoice from "./routes/salesInvoice";
import salesShipment from "./routes/salesShipment";
import salesReturnOrder from "./routes/salesReturnOrder";
import salesCreditMemo from "./routes/salesCreditMemo";
import transferReceipt from "./routes/transferReceipt";
import transferShipment from "./routes/transferShipment";
import purchaseInvoice from "./routes/purchaseInvoice";
import postPurchaseInvoice from "./routes/postPurchaseInvoice";
import postTransferReceipt from "./routes/postTransferReceipt";
import postTransferShipment from "./routes/postTransferShipment";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        pageTitle: "breadcrumb.dashboard",
        resource: "agent-dashboard",
        breadcrumb: [
          {
            text: "breadcrumb.dashboard",
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...outlet,
    ...product,
    ...order,
    ...salesOrder,
    ...salesInvoice,
    ...salesShipment,
    ...salesReturnOrder,
    ...salesCreditMemo,
    ...transferReceipt,
    ...transferShipment,
    ...purchaseInvoice,
    ...postPurchaseInvoice,
    ...postTransferReceipt,
    ...postTransferShipment,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  Vue.nextTick(() => {
    const { appName } = $themeConfig.app;
    document.title = `${to.meta.title ? i18n.tc(to.meta.title) : i18n.tc(to.meta.pageTitle)} | ${i18n.tc(appName)}`;
  });

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({
        name: "login", query: {
          redirect: to.fullPath
        }
      })
    }

    // If logged in => not authorized
    return next({ name: "not-authorized" })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? "admin" : null))
  }

  return next()
});

export default router;
