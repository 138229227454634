export default [
  {
    path: '/purchase-invoice',
    name: 'list-purchase-invoice',
    component: () => import('@/views/purchaseInvoice/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.purchaseInvoice',
      resource: 'agent-purchase-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPurchaseInvoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-invoice/:id',
    name: 'view-purchase-invoice',
    component: () => import('@/views/purchaseInvoice/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.purchaseInvoice',
      resource: 'agent-purchase-invoice',
      navActiveLink: 'list-purchase-invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPurchaseInvoice',
          to: { name: 'list-purchase-invoice' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
