import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/agent/product`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    if (payload.search) {
      let filters = {
        filters: [{
          searchby: "product_code",
          searchoperator: "%",
          search: payload.search
        }, {
          searchby: "name_en",
          searchoperator: "%",
          search: payload.search
        }, {
          searchby: "name_km",
          searchoperator: "%",
          search: payload.search
        }],
        filtertype: "OR",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  sync(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "sync",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "product_code",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_en",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_km",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries, "OR");
    let filters = {
      filters: [],
      filtertype: "AND",
    };
    payload.query.forEach(element => {
      filters.filters.push(element);
    });
    params.filters.push(filters);
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
