export default [
  {
    title: 'breadcrumb.order',
    route: 'list-order',
    icon: 'la-shopping-cart',
    iconType: 'line-awesome',
    resource: 'agent-order',
    action: 'read',
    tag: 0,
    tagVariant: "danger",
  },
];