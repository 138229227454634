import ProfileRepository from "./Api/ProfileRepository";
import DashboardRepository from "./Api/DashboardRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import OutletRepository from "./Api/OutletRepository";
import ProductCategoryRepository from "./Api/ProductCategoryRepository";
import ProductBrandRepository from "./Api/ProductBrandRepository";
import ProductRepository from "./Api/ProductRepository";
import PaymentMethodRepository from "./Api/PaymentMethodRepository";
import OrderRepository from "./Api/OrderRepository";
import NotificationRepository from "./Api/NotificationRepository";
import NotificationTokenRepository from "./Api/NotificationTokenRepository";
import CartRepository from "./Api/CartRepository";
import SalespersonRepository from "./Api/SalespersonRepository";
import SalesOrderRepository from "./Api/SalesOrderRepository";
import SalesOrderActionRepository from "./Api/SalesOrderActionRepository";
import SalesInvoiceRepository from "./Api/SalesInvoiceRepository";
import SalesShipmentRepository from "./Api/SalesShipmentRepository";
import SalesCreditMemoRepository from "./Api/SalesCreditMemoRepository";
import DpDashboardRepository from "./Api/DpDashboardRepository";
import TransferReceiptRepository from "./Api/TransferReceiptRepository";
import TransferShipmentRepository from "./Api/TransferShipmentRepository";
import PurchaseInvoiceRepository from "./Api/PurchaseInvoiceRepository";
import PostPurchaseInvoiceRepository from "./Api/PostPurchaseInvoiceRepository";
import ReasonCodeRepository from "./Api/ReasonCodeRepository";
import PostTransferReceiptRepository from "./Api/PostTransferReceiptRepository";
import PostTransferShipmentRepository from "./Api/PostTransferShipmentRepository";
import OutletShippingOptionRepository from "./Api/OutletShippingOptionRepository";
import SalesReasonCodeRepository from "./Api/SalesReasonCodeRepository";

const repositories = {
  'profile': ProfileRepository,
  'dashboard': DashboardRepository,
  'user': UserRepository,
  'role': RoleRepository,
  'permission': PermissionRepository,
  'file': FileRepository,
  'outlet': OutletRepository,
  'productCategory': ProductCategoryRepository,
  'productBrand': ProductBrandRepository,
  'product': ProductRepository,
  'paymentMethod': PaymentMethodRepository,
  'order': OrderRepository,
  'notification': NotificationRepository,
  'notificationToken': NotificationTokenRepository,
  'cart': CartRepository,
  'salesperson': SalespersonRepository,
  'salesOrder': SalesOrderRepository,
  'salesOrderAction': SalesOrderActionRepository,
  'salesInvoice': SalesInvoiceRepository,
  'salesShipment': SalesShipmentRepository,
  'salesCreditMemo': SalesCreditMemoRepository,
  'dpDashboard': DpDashboardRepository,
  'transferReceipt': TransferReceiptRepository,
  'transferShipment': TransferShipmentRepository,
  'purchaseInvoice': PurchaseInvoiceRepository,
  'postPurchaseInvoice': PostPurchaseInvoiceRepository,
  'reasonCode': ReasonCodeRepository,
  'postTransferReceipt': PostTransferReceiptRepository,
  'postTransferShipment': PostTransferShipmentRepository,
  'outletShippingOption': OutletShippingOptionRepository,
  'salesReasonCode': SalesReasonCodeRepository,
};

export default {
  get: name => repositories[name]
};
