export default [
  {
    path: '/sales-return-order',
    name: 'list-sales-return-order',
    component: () => import('@/views/salesReturnOrder/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesReturnOrder',
      resource: 'agent-sales-return-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.salesReturnOrderHistory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-return-order/create',
    name: 'create-sales-return-order',
    component: () => import('@/views/salesReturnOrder/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesReturnOrder',
      resource: 'agent-sales-return-order',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.salesReturnOrderHistory',
          to: { name: 'list-sales-return-order' }
        },
        {
          text: 'breadcrumb.makeOrder',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales-return-order/:id',
    name: 'view-sales-return-order',
    component: () => import('@/views/salesReturnOrder/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.salesReturnOrder',
      resource: 'agent-sales-return-order',
      navActiveLink: 'list-sales-return-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.salesReturnOrderHistory',
          to: { name: 'list-sales-return-order' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
