export default [
  {
    path: '/post-transfer-receipt',
    name: 'list-post-transfer-receipt',
    component: () => import('@/views/postTransferReceipt/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.postTransferReceipt',
      resource: 'agent-post-transfer-receipt',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostTransferReceipt',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post-transfer-receipt/:id',
    name: 'view-post-transfer-receipt',
    component: () => import('@/views/postTransferReceipt/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.postTransferReceipt',
      resource: 'agent-post-transfer-receipt',
      navActiveLink: 'list-post-transfer-receipt',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPostTransferReceipt',
          to: { name: 'list-post-transfer-receipt' }
        },
        {
          text: 'breadcrumb.detail',
          active: true,
        },
      ],
    },
  },
]
